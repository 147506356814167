import React from 'react'
import { MDXTag } from '@mdx-js/tag'



export const frontmatter = {
  title: 'Neon With Other Libraries',
  author: 'Chris Neale',
  draft: false,
  date: '2018-10-04T23:46:37.121Z',
  tags: ['neon', 'effects']
}
export default ({components, ...props}) => <MDXTag name="wrapper"  components={components}>
<MDXTag name="h3" components={components}>{`Neon and Other Libraries`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="a" components={components} parentName="p" props={{"href":"#twgl"}}>{`TWGL.js`}</MDXTag><MDXTag name="br" components={components} parentName="p"></MDXTag>
<MDXTag name="a" components={components} parentName="p" props={{"href":"#three"}}>{`Three.js`}</MDXTag><MDXTag name="br" components={components} parentName="p"></MDXTag>
<MDXTag name="a" components={components} parentName="p" props={{"href":"#two"}}>{`two.js`}</MDXTag><MDXTag name="br" components={components} parentName="p"></MDXTag>
<MDXTag name="a" components={components} parentName="p" props={{"href":"#fabric"}}>{`fabric.js`}</MDXTag><MDXTag name="br" components={components} parentName="p"></MDXTag>
<MDXTag name="a" components={components} parentName="p" props={{"href":"#paper"}}>{`paper.js`}</MDXTag></MDXTag>
<MDXTag name="h4" components={components}><a name="twgl">
</a>

{` TWGL.js`}</MDXTag>
<MDXTag name="p" components={components}>{`TWGL is already built in through the Shader effect if you want to render a GLSL shader.`}</MDXTag>
<MDXTag name="h4" components={components}><a name="three">
</a>

{` Three.js`}</MDXTag>
<MDXTag name="p" components={components}>{`An example of a loop of fairy lights (well, colored balls) around the outside of a component - `}<MDXTag name="a" components={components} parentName="p" props={{"href":"/fairy/"}}>{`Fairy Lights`}</MDXTag></MDXTag>
<MDXTag name="h4" components={components}><a name="two">
</a>

{` Two.js`}</MDXTag>
<MDXTag name="p" components={components}>{`Coming soon`}</MDXTag>
<MDXTag name="h4" components={components}><a name="fabric">
</a>

{` Fabric.js`}</MDXTag>
<MDXTag name="p" components={components}>{`Coming soon`}</MDXTag>
<MDXTag name="h4" components={components}><a name="paper">
</a>

{` Paper.js`}</MDXTag></MDXTag>

export const _frontmatter = {};

  